// @flow
import {
    DAILYREPORT_LIST,
    DAILYREPORT_LIST_SUCCESS,
    DAILYREPORT_LIST_FAILED,
    DAILYTARGET_LIST,
    DAILYTARGET_LIST_SUCCESS,
    DAILYTARGET_LIST_FAILED,
    DAILYTARGET_DETAILS,
    DAILYTARGET_DETAILS_SUCCESS,
    DAILYTARGET_DETAILS_FAILED,
    WEEKLYTARGET_DETAILS,
    WEEKLYTARGET_DETAILS_SUCCESS,
    WEEKLYTARGET_DETAILS_FAILED,
    DAILYTICKET_LIST,
    DAILYTICKET_LIST_SUCCESS,
    DAILYTICKET_LIST_FAILED,
    DAILYTICKET_DETAILS,
    DAILYTICKET_DETAILS_SUCCESS,
    DAILYTICKET_DETAILS_FAILED,
    WEEKLYTICKET_DETAILS,
    WEEKLYTICKET_DETAILS_SUCCESS,
    WEEKLYTICKET_DETAILS_FAILED,


} from './constants';

type DailyreportAction = { type: string, payload: {} | string };

export const getDailyreportList = (data:{}): DailyreportAction => ({
    type: DAILYREPORT_LIST,
    payload: data,
});

export const getDailyreportListSuccess = (dailyreport: string): DailyreportAction => ({
    type: DAILYREPORT_LIST_SUCCESS,
    payload: dailyreport,
});

export const getDailyreportListFailed = (error: string): DailyreportAction => ({
    type: DAILYREPORT_LIST_FAILED,
    payload: error,
});


export const getDailytargetList = (data:{}): DailyreportAction => ({
    type: DAILYTARGET_LIST,
    payload: data,
});

export const getDailytargetListSuccess = (targetreport: string): DailyreportAction => ({
    type: DAILYTARGET_LIST_SUCCESS,
    payload: targetreport,
});

export const getDailytargetListFailed = (error: string): DailyreportAction => ({
    type: DAILYTARGET_LIST_FAILED,
    payload: error,
});

///target details daily


export const getDailytargetDetailsList = (data:{}): DailyreportAction => ({
    type: DAILYTARGET_DETAILS,
    payload: data,
});

export const getDailytargetDetailsListSuccess = (targetdetailsreport: string): DailyreportAction => ({
    type: DAILYTARGET_DETAILS_SUCCESS,
    payload: targetdetailsreport,
});

export const getDailytargetDetailsListFailed = (error: string): DailyreportAction => ({
    type: DAILYTARGET_DETAILS_FAILED,
    payload: error,
});
///target details weekly

export const getWeeklytargetDetailsList = (data:{}): DailyreportAction => ({
    type: WEEKLYTARGET_DETAILS,
    payload: data,
});

export const getWeeklytargetDetailsListSuccess = (targetweeklyreport: string): DailyreportAction => ({
    type: WEEKLYTARGET_DETAILS_SUCCESS,
    payload: targetweeklyreport,
});

export const getWeeklytargetDetailsListFailed = (error: string): DailyreportAction => ({
    type: WEEKLYTARGET_DETAILS_FAILED,
    payload: error,
});


///TICKET DETAILS


export const getDailyticketList = (data:{}): DailyreportAction => ({
    type: DAILYTICKET_LIST,
    payload: data,
});

export const getDailyticketListSuccess = (ticketreport: string): DailyreportAction => ({
    type: DAILYTICKET_LIST_SUCCESS,
    payload: ticketreport,
});

export const getDailyticketListFailed = (error: string): DailyreportAction => ({
    type: DAILYTICKET_LIST_FAILED,
    payload: error,
});
///TICKET details weekly

export const getWeeklyticketDetailsList = (data:{}): DailyreportAction => ({
    type: WEEKLYTICKET_DETAILS,
    payload: data,
});

export const getWeeklyticketDetailsListSuccess = (ticketweeklyreport: string): DailyreportAction => ({
    type: WEEKLYTICKET_DETAILS_SUCCESS,
    payload: ticketweeklyreport,
});

export const getWeeklyticketDetailsListFailed = (error: string): DailyreportAction => ({
    type: WEEKLYTICKET_DETAILS_FAILED,
    payload: error,
});


export const getDailyticketDetailsList = (data:{}): DailyreportAction => ({
    type: DAILYTICKET_DETAILS,
    payload: data,
});

export const getDailyticketDetailsListSuccess = (targetdetailsreport: string): DailyreportAction => ({
    type: DAILYTICKET_DETAILS_SUCCESS,
    payload: targetdetailsreport,
});

export const getDailyticketDetailsListFailed = (error: string): DailyreportAction => ({
    type: DAILYTICKET_DETAILS_FAILED,
    payload: error,
});