// @flow
import {
    WORK_DATA_LIST,
    WORK_DATA_LIST_SUCCESS,
    WORK_DATA_LIST_FAILED,
   

} from './constants';

type viewReportListAction = { type: string, payload: {} | string };



//logout
export const getEmployeeViewReportList = (data:{}): viewReportListAction => ({
    type:WORK_DATA_LIST,
    payload: data,
});

export const getEmployeeViewReportListSuccess = (viewReportEmployeeList: string): viewReportListAction => ({
    type: WORK_DATA_LIST_SUCCESS,
    payload: viewReportEmployeeList,
});

export const getEmployeeViewReportListFailed = (error: string): viewReportListAction => ({
    type:  WORK_DATA_LIST_FAILED,
    payload: error,
});
