// @flow
import {
    ONBOARD_LIST,
    ONBOARD_LIST_SUCCESS,
    ONBOARD_LIST_FAILED,
    ONBOARD_ADD,
    ONBOARD_ADD_SUCCESS,
    ONBOARD_ADD_FAILED,
    ONBOARD_UPDATE,
    ONBOARD_UPDATE_SUCCESS,
    ONBOARD_UPDATE_FAILED,
    ONBOARD_DELETE,
    ONBOARD_DELETE_SUCCESS,
    ONBOARD_DELETE_FAILED,
    ONBOARD_LISTVIEW,
    ONBOARD_LISTVIEW_SUCCESS,
    ONBOARD_LISTVIEW_FAILED,
    ONBOARD_INVITE,
    ONBOARD_INVITE_SUCCESS,
    ONBOARD_INVITE_FAILED,
    WELCOME_LIST,
    WELCOME_LIST_SUCCESS,
    WELCOME_LIST_FAILED,
    WELCOME_UPDATE,
    WELCOME_UPDATE_SUCCESS,
    WELCOME_UPDATE_FAILED,
    WELCOME_MAIL,
    WELCOME_MAIL_SUCCESS,
    WELCOME_MAIL_FAILED,
    WELCOME_APPROVE,
    WELCOME_APPROVE_SUCCESS,
    WELCOME_APPROVE_FAILED,
    WELCOME_VIEW,
    WELCOME_VIEW_SUCCESS,
    WELCOME_VIEW_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type OnboardAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Onboard = (state: State = INIT_STATE, action: OnboardAction) => {
    switch (action.type) {
        case ONBOARD_LIST:
            return { ...state, listloading: true };
        case ONBOARD_LIST_SUCCESS:
            return { ...state, onboard: action.payload, listloading: false, error: null };
        case ONBOARD_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ONBOARD_ADD:
            return { ...state, loading: true };
        case ONBOARD_ADD_SUCCESS:
            return { ...state, onboardAdd: action.payload, loading: false, error: null };
        case ONBOARD_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ONBOARD_UPDATE:
            return { ...state, loading: true };
        case ONBOARD_UPDATE_SUCCESS:
            return { ...state, onboardUpdate: action.payload, loading: false, error: null };
        case ONBOARD_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ONBOARD_DELETE:
            return { ...state, loading: true };
        case ONBOARD_DELETE_SUCCESS:
            return { ...state, onboardDelete: action.payload, loading: false, error: null };
        case ONBOARD_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };
         case ONBOARD_LISTVIEW:
             return { ...state, listloading: true };
         case ONBOARD_LISTVIEW_SUCCESS:
             return { ...state, onboardView: action.payload, listloading: false, error: null };
         case ONBOARD_LISTVIEW_FAILED:
             return { ...state, error: action.payload, listloading: false };   
             case ONBOARD_INVITE:
                return { ...state, loading: true };
            case ONBOARD_INVITE_SUCCESS:
                return { ...state, onboardInvite: action.payload, loading: false, error: null };
            case ONBOARD_INVITE_FAILED:
                return { ...state, error: action.payload, loading: false };    

                //WELCOME

                case WELCOME_LIST:
                    return { ...state, listloading: true };
                case WELCOME_LIST_SUCCESS:
                    return { ...state, welcomeData: action.payload, listloading: false, error: null };
                case WELCOME_LIST_FAILED:
                    return { ...state, error: action.payload, loading: false };

                    case WELCOME_UPDATE:
                        return { ...state, loading: true };
                    case WELCOME_UPDATE_SUCCESS:
                        return { ...state, welcomeUpdate: action.payload, loading: false, error: null };
                    case WELCOME_UPDATE_FAILED:
                        return { ...state, error: action.payload, loading: false };


                        case WELCOME_MAIL:
                            return { ...state, loading: true };
                        case WELCOME_MAIL_SUCCESS:
                            return { ...state, welcomeMail: action.payload, loading: false, error: null };
                        case WELCOME_MAIL_FAILED:
                            return { ...state, error: action.payload, loading: false };

                            case WELCOME_APPROVE:
                                return { ...state, loading: true };
                            case WELCOME_APPROVE_SUCCESS:
                                return { ...state, welcomeApprove: action.payload, loading: false, error: null };
                            case WELCOME_APPROVE_FAILED:
                                return { ...state, error: action.payload, loading: false };

                                case WELCOME_VIEW:
                                    return { ...state, loading: true };
                                case WELCOME_VIEW_SUCCESS:
                                    return { ...state, welcomeView: action.payload, loading: false, error: null };
                                case WELCOME_VIEW_FAILED:
                                    return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Onboard;
