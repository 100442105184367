/* EMPLOYEE */
export const EMPLOYEE_LIST = "EMPLOYEE_LIST";
export const EMPLOYEE_LIST_USER = "EMPLOYEE_LIST_USER";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_FAILED = "EMPLOYEE_LIST_FAILED";

export const EMPLOYEE_ADD = "EMPLOYEE_ADD";
export const EMPLOYEE_ADD_SUCCESS = "EMPLOYEE_ADD_SUCCESS";
export const EMPLOYEE_ADD_FAILED = "EMPLOYEEADD_ADD_FAILED";

export const EMPLOYEE_UPDATE = "EMPLOYEE_UPDATE";
export const EMPLOYEE_UPDATE_SUCCESS = "EMPLOYEE_UPDATE_SUCCESS";
export const EMPLOYEE_UPDATE_FAILED = "EMPLOYEE_UPDATE_FAILED";

export const EMPLOYEE_DELETE = "EMPLOYEE_DELETE";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_FAILED = "EMPLOYEEADD_DELETE_FAILED";

export const RESIGNED_EMPLOYEE_LIST = "RESIGNED_EMPLOYEE_LIST";
export const RESIGNED_EMPLOYEE_LIST_SUCCESS = "RESIGNED_EMPLOYEE_LIST_SUCCESS";
export const RESIGNED_EMPLOYEE_LIST_FAILED = "RESIGNED_EMPLOYEE_LIST_FAILED";

export const EMPLOYEE_SKILL_LIST = "EMPLOYEE_SKILL_LIST";
export const EMPLOYEE_SKILL_LIST_SUCCESS = "EMPLOYEE_SKILL_LIST_SUCCESS";
export const EMPLOYEE_SKILL_LIST_FAILED = "EMPLOYEE_SKILL_LIST_FAILED";

export const EMPLOYEE_SKILL_ADD = "EMPLOYEE_SKILL_ADD";
export const EMPLOYEE_SKILL_ADD_SUCCESS = "EMPLOYEE_SKILL_ADD_SUCCESS";
export const EMPLOYEE_SKILL_ADD_FAILED = "EMPLOYEE_SKILL_ADD_FAILED";

export const EMPLOYEE_SKILL_DELETE = "EMPLOYEE_SKILL_DELETE";
export const EMPLOYEE_SKILL_DELETE_SUCCESS = "EMPLOYEE_SKILL_DELETE_SUCCESS";
export const EMPLOYEE_SKILL_DELETE_FAILED = "EMPLOYEE_SKILL_DELETE_FAILED";

export const EMPLOYEE_SKILL_COMPLETE_DELETE = "EMPLOYEE_SKILL_COMPLETE_DELETE";
export const EMPLOYEE_SKILL_COMPLETE_DELETE_SUCCESS =
  "EMPLOYEE_SKILL_COMPLETE_DELETE_SUCCESS";
export const EMPLOYEE_SKILL_COMPLETE_DELETE_FAILED =
  "EMPLOYEE_SKILL_COMPLETE_DELETE_FAILED";

export const EMPLOYEE_SKILL_REVIEW = "EMPLOYEE_SKILL_REVIEW";
export const EMPLOYEE_SKILL_REVIEW_SUCCESS = "EMPLOYEE_SKILL_REVIEW_SUCCESS";
export const EMPLOYEE_SKILL_REVIEW_FAILED = "EMPLOYEE_SKILL_REVIEW_FAILED";

export const EMPLOYEE_PROFILE_ADD = "EMPLOYEE_PROFILE_ADD";
export const EMPLOYEE_PROFILE_ADD_SUCCESS = "EMPLOYEE_PROFILE_ADD_SUCCESS";
export const EMPLOYEE_PROFILE_ADD_FAILED = "EMPLOYEE_PROFILE_ADD_FAILED";

export const EMPLOYEE_LOGIN = "EMPLOYEE_LOGIN";
export const EMPLOYEE_LOGIN_SUCCESS = "EMPLOYEE_LOGIN_SUCCESS";
export const EMPLOYEE_LOGIN_FAILED = "EMPLOYEELOGIN_LOGIN_FAILED";

export const EMPLOYEE_PERFORMANCE = "EMPLOYEE_PERFORMANCE";
export const EMPLOYEE_PERFORMANCE_SUCCESS = "EMPLOYEE_PERFORMANCE_SUCCESS";
export const EMPLOYEE_PERFORMANCE_FAILED = "EMPLOYEE_PERFORMANCE_FAILED";

export const EMPLOYEE_RESET_OVERTIME = "EMPLOYEE_RESET_OVERTIME";
export const EMPLOYEE_RESET_OVERTIME_SUCCESS =
  "EMPLOYEE_RESET_OVERTIME_SUCCESS";
export const EMPLOYEE_RESET_OVERTIME_FAILED = "EMPLOYEE_RESET_OVERTIME_FAILED";

export const EMPLOYEE_UPDATE_MANDATORY = "EMPLOYEE_UPDATE_MANDATORY";
export const EMPLOYEE_UPDATE_MANDATORY_SUCCESS =
  "EMPLOYEE_UPDATE_MANDATORY_SUCCESS";
export const EMPLOYEE_UPDATE_MANDATORY_FAILED =
  "EMPLOYEE_UPDATE_MANDATORY_FAILED";

export const EMPLOYEE_WARNING = "EMPLOYEE_WARNING";
export const EMPLOYEE_WARNING_SUCCESS = "EMPLOYEE_WARNING_SUCCESS";
export const EMPLOYEE_WARNING_FAILED = "EMPLOYEE_WARNING_FAILED";

export const PERFROMANCE_UPDATE = "PERFROMANCE_UPDATE";
export const PERFROMANCE_UPDATE_SUCCESS = "PERFROMANCE_UPDATE_SUCCESS";
export const PERFROMANCE_UPDATE_FAILED = "PERFROMANCE_UPDATE_FAILED";



export const WARNED_EMPLOYEE_LIST='WARNED_EMPLOYEE_LIST';
export const WARNED_EMPLOYEE_LIST_SUCCESS='WARNED_EMPLOYEE_LIST_SUCCESS';
export const WARNED_EMPLOYEE_LIST_FAILED='WARNED_EMPLOYEE_LIST_FAILED';

export const SUSPENDED_EMPLOYEE_LIST='SUSPENDED_EMPLOYEE_LIST';
export const SUSPENDED_EMPLOYEE_LIST_SUCCESS='SUSPENDED_EMPLOYEE_LIST_SUCCESS';
export const SUSPENDED_EMPLOYEE_LIST_FAILED='SUSPENDED_EMPLOYEE_LIST_FAILED';




export const EMPLOYEE_PASSWORD_UPDATE='EMPLOYEE_PASSWORD_UPDATE';
export const EMPLOYEE_PASSWORD_UPDATE_SUCCESS='EMPLOYEE_PASSWORD_UPDATE_SUCCESS';
export const EMPLOYEE_PASSWORD_UPDATE_FAILED='EMPLOYEE_PASSWORD_UPDATE_FAILED';


export const EMPLOYEE_SINGLE_DETAILS='EMPLOYEE_SINGLE_DETAILS';
export const EMPLOYEE_SINGLE_DETAILS_SUCCESS='EMPLOYEE_SINGLE_DETAILS_SUCCESS';
export const EMPLOYEE_SINGLE_DETAILS_FAILED='EMPLOYEE_SINGLE_DETAILS_FAILED';





export const EMPLOYEE_SINGLE_ADD='EMPLOYEE_SINGLE_ADD_FAILED';

export const EMPLOYEE_SINGLE_ADD_SUCCESS='EMPLOYEE_SINGLE_ADD_FAILED';

export const EMPLOYEE_SINGLE_ADD_FAILED='EMPLOYEE_SINGLE_ADD_FAILED';



export const EMPLOYEE_SINGLE_GET_DETAILS='EMPLOYEE_SINGLE_GET_DETAILS';

export const EMPLOYEE_SINGLE_GET_DETAILS_SUCCESS='EMPLOYEE_SINGLE_GET_DETAILS_SUCCESS';

export const EMPLOYEE_SINGLE_GET_DETAILS_FAILED='EMPLOYEE_SINGLE_GET_DETAILS_FAILED';



export const  WFHCANCEL_EMPLOYEE_LIST='WFHCANCEL_EMPLOYEE_LIST';
export const  WFHCANCEL_EMPLOYEE_LIST_SUCCESS='WFHCANCEL_EMPLOYEE_LIST_SUCCESS';
export const  WFHCANCEL_EMPLOYEE_LIST_FAILED='WFHCANCEL_EMPLOYEE_LIST_FAILED';


export const BACKUP_EMPLOYEE_LIST='BACKUP_EMPLOYEE_LIST';
export const BACKUP_EMPLOYEE_LIST_SUCCESS='BACKUP_EMPLOYEE_LIST_SUCCESS';
export const BACKUP_EMPLOYEE_LIST_FAILED='BACKUP_EMPLOYEE_LIST_FAILED';


export const NOTICE_EMPLOYEE_LIST='NOTICE_EMPLOYEE_LIST';
export const NOTICE_EMPLOYEE_LIST_SUCCESS='NOTICE_EMPLOYEE_LIST_SUCCESS';
export const NOTICE_EMPLOYEE_LIST_FAILED='NOTICE_EMPLOYEE_LIST_FAILED';