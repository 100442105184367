// @flow
import {
    ONBOARD_LIST,
    ONBOARD_LIST_SUCCESS,
    ONBOARD_LIST_FAILED,
    ONBOARD_ADD,
    ONBOARD_ADD_SUCCESS,
    ONBOARD_ADD_FAILED,
    ONBOARD_UPDATE,
    ONBOARD_UPDATE_SUCCESS,
    ONBOARD_UPDATE_FAILED,
    ONBOARD_DELETE,
    ONBOARD_DELETE_SUCCESS,
    ONBOARD_DELETE_FAILED,
    ONBOARD_LISTVIEW,
    ONBOARD_LISTVIEW_SUCCESS,
    ONBOARD_LISTVIEW_FAILED,
    ONBOARD_INVITE,
    ONBOARD_INVITE_SUCCESS,
    ONBOARD_INVITE_FAILED,
    //welcome
    WELCOME_LIST,
    WELCOME_LIST_SUCCESS,
    WELCOME_LIST_FAILED,
    WELCOME_UPDATE,
    WELCOME_UPDATE_SUCCESS,
    WELCOME_UPDATE_FAILED,
    WELCOME_MAIL,
    WELCOME_MAIL_SUCCESS,
    WELCOME_MAIL_FAILED,
    WELCOME_APPROVE,
    WELCOME_APPROVE_SUCCESS,
    WELCOME_APPROVE_FAILED,

    WELCOME_VIEW,
    WELCOME_VIEW_SUCCESS,
    WELCOME_VIEW_FAILED,


} from './constants';

type OnboardAction = { type: string, payload: {} | string };

export const getOnboardList = (): OnboardAction => ({
    type: ONBOARD_LIST,
    payload: {},
});

export const getOnboardListSuccess = (onboard: string): OnboardAction => ({
    type: ONBOARD_LIST_SUCCESS,
    payload: onboard,
});

export const getOnboardListFailed = (error: string): OnboardAction => ({
    type: ONBOARD_LIST_FAILED,
    payload: error,
});

export const getOnboardAdd = (data:{}): OnboardAction => ({
    type: ONBOARD_ADD,
    payload: data,
});

export const getOnboardAddSuccess = (onboardAdd: string): OnboardAction => ({
    type: ONBOARD_ADD_SUCCESS,
    payload: onboardAdd,
});

export const getOnboardAddFailed = (error: string): OnboardAction => ({
    type: ONBOARD_ADD_FAILED,
    payload: error,
});

export const getOnboardUpdate = (data:{}): OnboardAction => ({
    type: ONBOARD_UPDATE,
    payload: data,
});

export const getOnboardUpdateSuccess = (onboardUpdate: string): OnboardAction => ({
    type: ONBOARD_UPDATE_SUCCESS,
    payload: onboardUpdate,
});

export const getOnboardUpdateFailed = (error: string): OnboardAction => ({
    type: ONBOARD_UPDATE_FAILED,
    payload: error,
});

export const getOnboardDelete = (id): OnboardAction => ({
    type: ONBOARD_DELETE,
    payload: id,
});

export const getOnboardDeleteSuccess = (onboardDelete: string): OnboardAction => ({
    type: ONBOARD_DELETE_SUCCESS,
    payload: onboardDelete,
});

export const getOnboardDeleteFailed = (error: string): OnboardAction => ({
    type: ONBOARD_DELETE_FAILED,
    payload: error,
});
//
export const getOnboardListView = (data:{}): OnboardAction => ({
    type: ONBOARD_LISTVIEW,
    payload: data,
});

export const getOnboardListViewSuccess = (onboardView: string): OnboardAction => ({
    type: ONBOARD_LISTVIEW_SUCCESS,
    payload: onboardView,
});

export const getOnboardListViewFailed = (error: string): OnboardAction => ({
    type: ONBOARD_LISTVIEW_FAILED,
    payload: error,
});



export const getOnboardInvite = (id): OnboardAction => ({
    type: ONBOARD_INVITE,
    payload: id,
});

export const getOnboardInviteSuccess = (onboardInvite: string): OnboardAction => ({
    type: ONBOARD_INVITE_SUCCESS,
    payload: onboardInvite,
});

export const getOnboardInviteFailed = (error: string): OnboardAction => ({
    type: ONBOARD_INVITE_FAILED,
    payload: error,
});


///welcome

export const getWelcomeList = (): OnboardAction => ({
    type: WELCOME_LIST,
    payload: {},
});

export const getWelcomeListSuccess = (welcomeData: string): OnboardAction => ({
    type: WELCOME_LIST_SUCCESS,
    payload: welcomeData,
});

export const getWelcomeListFailed = (error: string): OnboardAction => ({
    type: WELCOME_LIST_FAILED,
    payload: error,
});
export const getWelcomeUpdate = (data:{}): OnboardAction => ({
    type: WELCOME_UPDATE,
    payload: data,
});

export const getWelcomeUpdateSuccess = (welcomeUpdate: string): OnboardAction => ({
    type: WELCOME_UPDATE_SUCCESS,
    payload: welcomeUpdate,
});

export const getWelcomeUpdateFailed = (error: string): OnboardAction => ({
    type: WELCOME_UPDATE_FAILED,
    payload: error,
});


export const getWelcomeMail = (data:{}): OnboardAction => ({
    type: WELCOME_MAIL,
    payload: data,
});

export const getWelcomeMailSuccess = (welcomeMail: string): OnboardAction => ({
    type: WELCOME_MAIL_SUCCESS,
    payload: welcomeMail,
});

export const getWelcomeMailFailed = (error: string): OnboardAction => ({
    type: WELCOME_MAIL_FAILED,
    payload: error,
});

//approval

export const getWelcomeApproval = (data:{}): OnboardAction => ({
    type: WELCOME_APPROVE,
    payload: data,
});

export const getWelcomeApprovalSuccess = (welcomeApprove: string): OnboardAction => ({
    type: WELCOME_APPROVE_SUCCESS,
    payload: welcomeApprove,
});

export const getWelcomeApprovalFailed = (error: string): OnboardAction => ({
    type: WELCOME_APPROVE_FAILED,
    payload: error,
});

//view

export const getWelcomeView = (data:{}): OnboardAction => ({
    type: WELCOME_VIEW,
    payload: data,
});

export const getWelcomeViewSuccess = (welcomeView: string): OnboardAction => ({
    type: WELCOME_VIEW_SUCCESS,
    payload: welcomeView,
});

export const getWelcomeViewFailed = (error: string): OnboardAction => ({
    type: WELCOME_VIEW_FAILED,
    payload: error,
});