// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { ADMINHASH_LIST, ADMINHASH_ADD, ADMINHASH_UPDATE, ADMINHASH_DELETE ,ADMINHASH_SHOW,
    ADMINHASH_SHOW_SUCCESS,
    ADMINHASH_SHOW_FAILED,

    TOPICHASH_LIST, TOPICHASH_ADD, TOPICHASH_UPDATE, TOPICHASH_DELETE ,TOPICHASH_SHOW,

    TOPICHASH_LOCK,
    TOPICHASH_LOCK_SUCCESS,
    TOPICHASH_LOCK_FAILED,
    TOPICHASH_UNLOCK,
    TOPICHASH_UNLOCK_SUCCESS,
    TOPICHASH_UNLOCK_FAILED,
} from './constants';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    getAdminhashListSuccess,
    getAdminhashListFailed,
    getAdminhashAddSuccess,
    getAdminhashAddFailed,
    getAdminhashUpdateSuccess,
    getAdminhashUpdateFailed,
    getAdminhashDeleteSuccess,
    getAdminhashDeleteFailed,

    getAdminhashShowList,
    getAdminhashShowListSuccess,
    getAdminhashShowListFailed,

    getTopichashListSuccess,
    getTopichashListFailed,
    getTopichashAddSuccess,
    getTopichashAddFailed,
    getTopichashUpdateSuccess,
    getTopichashUpdateFailed,
    getTopichashDeleteSuccess,
    getTopichashDeleteFailed,

    getTopichashShowList,
    getTopichashShowListSuccess,
    getTopichashShowListFailed,

    getTopichashLock,
    getTopichashLockSuccess,
    getTopichashLockFailed,

    getTopichashUnLock,
    getTopichashUnLockSuccess,
    getTopichashUnLockFailed

} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const AdminhashAddedSucsess = () => toast.success('HashBook Added Successfully', { transition: Zoom });
const AdminhashDeletedSuccess = () => toast.success('HashBook Deleted Successfully', { transition: Zoom });
const AdminhashUpdated = () => toast.info('HashBook Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });


const TopichashAddedSucsess = () => toast.success('Topic Added Successfully', { transition: Zoom });
const TopichashDeletedSuccess = () => toast.success('Topic Deleted Successfully', { transition: Zoom });
const TopichashUpdated = () => toast.info('Topic Updated Successfully', { transition: Zoom });

const TopichashLockSuccess = () => toast.info('Topic Locked Successfully', { transition: Zoom });
const TopichashUnLockSuccess = () => toast.info('Topic Unlocked Successfully', { transition: Zoom });

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* getAdminhashList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.adminHashbook,
        data: {

            "id":data.param_id,
          

         }
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getAdminhashListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminhashListFailed(message));
    }
}

// Client Add

function* AdminhashAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.adminhashAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        AdminhashAddedSucsess();
     //   console.log(response.data);
        yield put(getAdminhashAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminhashAddFailed(message));
    }
}

// Client Update

function* AdminhashUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.adminhashUpdate,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        AdminhashUpdated();
        yield put(getAdminhashUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminhashUpdateFailed(message));
    }
}

// Client Delete

function* AdminhashDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.adminhashDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        AdminhashDeletedSuccess();
        yield put(getAdminhashDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminhashDeleteFailed(message));
    }
}

function* AdminhashShowList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.adminhashShow,
        data: {

            "id":data.param_id,
          

         }
    };
    try {
        const response = yield call(ApiCall, options);

        yield put(getAdminhashShowListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminhashShowListFailed(message));
    }
}






////////////////////////////////////////


function* getTopichashList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.adminhashtopicshow,
        // data: sendData
    };

    try {
    
        const response = yield call(ApiCall, options);

        yield put(getTopichashListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTopichashListFailed(message));
    }
}



function* TopichashAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.adminhashtopicadd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        TopichashAddedSucsess();
     //   console.log(response.data);
        yield put(getTopichashAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getAdminhashAddFailed(message));
    }
}

function* TopichashDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.adminhashtopicdelete + '/' + id,
    };

    try {
      
        const response = yield call(ApiCall, options);
        TopichashDeletedSuccess();
        yield put(getTopichashDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTopichashDeleteFailed(message));
    }
}

function* TopichashUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.adminhashtopicUpdate + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
    TopichashUpdated();
        yield put(getTopichashUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTopichashUpdateFailed(message));
    }
}
//lock

function* TopichashLock({ payload: data }) {
    const user = getLoggedInUser();
console.log(data)
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.adminlockHashbook,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        TopichashLockSuccess();
     //   console.log(response.data);
        yield put(getTopichashLockSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTopichashLockFailed(message));
    }
}
//unlock
function* TopichashUnLock({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.adminlockHashbook,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        TopichashUnLockSuccess();
     //   console.log(response.data);
        yield put(getTopichashUnLockSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTopichashUnLockFailed(message));
    }
}

export function* watchgetAdminhashList(): any {
    yield takeEvery( ADMINHASH_LIST, getAdminhashList);
}
export function* watchAdminhashAdd(): any {
    yield takeEvery( ADMINHASH_ADD, AdminhashAdd);
}
export function* watchAdminhashUpdate(): any {
    yield takeEvery( ADMINHASH_UPDATE, AdminhashUpdate);
}
export function* watchAdminhashDelete(): any {
    yield takeEvery( ADMINHASH_DELETE, AdminhashDelete);
}
export function* watchAdminhashShowList(): any {
    yield takeEvery( ADMINHASH_SHOW, AdminhashShowList);
}



export function* watchGetTopichashList(): any {
    yield takeEvery(TOPICHASH_LIST, getTopichashList);
}
export function* watchTopichashAdd(): any {
    yield takeEvery( TOPICHASH_ADD, TopichashAdd);
}
export function* watchTopichashDelete(): any {
    yield takeEvery( TOPICHASH_DELETE, TopichashDelete);
}
export function* watchTopichashUpdate(): any {
    yield takeEvery( TOPICHASH_UPDATE, TopichashUpdate);
}

export function* watchTopichashLock(): any {
    yield takeEvery( TOPICHASH_LOCK, TopichashLock);
}
export function* watchTopichashUnlock(): any {
    yield takeEvery( TOPICHASH_UNLOCK, TopichashUnLock);
}




function* authSaga(): any {
    yield all([fork(watchTopichashUpdate),fork(watchTopichashDelete),fork(watchgetAdminhashList), fork(watchAdminhashAdd), fork(watchAdminhashUpdate), fork(watchAdminhashDelete),fork(watchAdminhashShowList),fork(watchGetTopichashList),fork(watchTopichashAdd),
        fork(watchTopichashLock),fork(watchTopichashUnlock)

        
    ]);
}

export default authSaga;
