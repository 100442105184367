// @flow
import {
    DAILYREPORT_LIST,
    DAILYREPORT_LIST_SUCCESS,
    DAILYREPORT_LIST_FAILED,
    DAILYTARGET_LIST,
    DAILYTARGET_LIST_SUCCESS,
    DAILYTARGET_LIST_FAILED,
    DAILYTARGET_DETAILS,
    DAILYTARGET_DETAILS_SUCCESS,
    DAILYTARGET_DETAILS_FAILED,
    WEEKLYTARGET_DETAILS,
    WEEKLYTARGET_DETAILS_SUCCESS,
    WEEKLYTARGET_DETAILS_FAILED,
    DAILYTICKET_LIST,
    DAILYTICKET_LIST_SUCCESS,
    DAILYTICKET_LIST_FAILED,
    DAILYTICKET_DETAILS,
    DAILYTICKET_DETAILS_SUCCESS,
    DAILYTICKET_DETAILS_FAILED,
    WEEKLYTICKET_DETAILS,
    WEEKLYTICKET_DETAILS_SUCCESS,
    WEEKLYTICKET_DETAILS_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type DailyreportAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Dailyreport = (state: State = INIT_STATE, action: DailyreportAction) => {
    switch (action.type) {
        case DAILYREPORT_LIST:
            return { ...state, listloading: true };
        case DAILYREPORT_LIST_SUCCESS:
            return { ...state, dailyreport: action.payload, listloading: false, error: null };
        case DAILYREPORT_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
            case DAILYTARGET_LIST:
                return { ...state, listloading: true };
            case DAILYTARGET_LIST_SUCCESS:
                return { ...state, targetreport: action.payload, listloading: false, error: null };
            case DAILYTARGET_LIST_FAILED:
                return { ...state, error: action.payload, listloading: false };


                case DAILYTARGET_DETAILS:
                    return { ...state, listloading: true };
                case DAILYTARGET_DETAILS_SUCCESS:
                    return { ...state, targetdetailsreport: action.payload, listloading: false, error: null };
                case DAILYTARGET_DETAILS_FAILED:
                    return { ...state, error: action.payload, loading: false };

                    case WEEKLYTARGET_DETAILS:
                        return { ...state, listloading: true };
                    case WEEKLYTARGET_DETAILS_SUCCESS:
                        return { ...state, targetweeklyreport: action.payload, listloading: false, error: null };
                    case WEEKLYTARGET_DETAILS_FAILED:
                        return { ...state, error: action.payload, loading: false };


                        //ticket

                        case DAILYTICKET_LIST:
                            return { ...state, listloading: true };
                        case DAILYTICKET_LIST_SUCCESS:
                            return { ...state, ticketreport: action.payload, listloading: false, error: null };
                        case DAILYTICKET_LIST_FAILED:
                            return { ...state, error: action.payload, loading: false };
            
            
                            case DAILYTICKET_DETAILS:
                                return { ...state, listloading: true };
                            case DAILYTICKET_DETAILS_SUCCESS:
                                return { ...state, ticketdetailsreport: action.payload, listloading: false, error: null };
                            case DAILYTICKET_DETAILS_FAILED:
                                return { ...state, error: action.payload, listloading: false };
            
                                case WEEKLYTICKET_DETAILS:
                                    return { ...state, listloading: true };
                                case WEEKLYTICKET_DETAILS_SUCCESS:
                                    return { ...state, ticketweeklyreport: action.payload, listloading: false, error: null };
                                case WEEKLYTICKET_DETAILS_FAILED:
                                    return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Dailyreport;
