// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DAILYREPORT_LIST,  DAILYTARGET_LIST,
    DAILYTARGET_LIST_SUCCESS,
    DAILYTARGET_LIST_FAILED ,    DAILYTARGET_DETAILS,
    DAILYTARGET_DETAILS_SUCCESS,
    DAILYTARGET_DETAILS_FAILED, WEEKLYTARGET_DETAILS,
    WEEKLYTARGET_DETAILS_SUCCESS,
    WEEKLYTARGET_DETAILS_FAILED,
    DAILYTICKET_LIST,
    DAILYTICKET_LIST_SUCCESS,
    DAILYTICKET_LIST_FAILED,
    DAILYTICKET_DETAILS,
    DAILYTICKET_DETAILS_SUCCESS,
    DAILYTICKET_DETAILS_FAILED,
    WEEKLYTICKET_DETAILS,
    WEEKLYTICKET_DETAILS_SUCCESS,
    WEEKLYTICKET_DETAILS_FAILED} from './constants';

import {
    getDailyreportListSuccess,
    getDailyreportListFailed,
    getDailytargetList,
    getDailytargetListSuccess,
    getDailytargetListFailed,
    getDailytargetDetailsList,
    getDailytargetDetailsListSuccess,
    getDailytargetDetailsListFailed,
    getWeeklytargetDetailsList,getWeeklytargetDetailsListSuccess,getWeeklytargetDetailsListFailed,
    getDailyticketList,
    getDailyticketListSuccess,
    getDailyticketListFailed,
    getDailyticketDetailsList,
    getDailyticketDetailsListSuccess,
    getDailyticketDetailsListFailed,
    getWeeklyicketDetailsList,getWeeklyticketDetailsListSuccess,getWeeklyticketDetailsListFailed

} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const dailyreportAddedSucsess = () => toast.success('Dailyreport Added Successfully', { transition: Zoom });
const dailyreportDeletedSuccess = () => toast.success('Dailyreport Deleted Successfully', { transition: Zoom });
const dailyreportUpdated = () => toast.info('Dailyreport Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* DailyreportList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
        url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        // url: endpoints.dailyreportList,
        // data: data
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getDailyreportListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyreportListFailed(message));
    }
}

//target
function* TargetreportList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.dailytargetList,
        data: data
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getDailytargetListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailytargetListFailed(message));
    }
}
//
function* TargetreportDetailsList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.dailytargetdetails,
        data: data
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getDailytargetDetailsListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailytargetDetailsListFailed(message));
    }
}
function* TargetreportweeklyDetailsList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.weeklytargetdetails,
        data: data
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getWeeklytargetDetailsListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getWeeklytargetDetailsListFailed(message));
    }
}

//ticket

function* TicketreportList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.dailyticketList,
        data: data
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getDailyticketListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyticketListFailed(message));
    }
}
//
function* TicketreportDetailsList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.dailyticketdetails,
        data: data
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getDailyticketDetailsListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyticketDetailsListFailed(message));
    }
}
function* TicketreportweeklyDetailsList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.weeklyticketdetails,
        data: data
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getWeeklyticketDetailsListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getWeeklyticketDetailsListFailed(message));
    }
}
export function* watchDailyreportList(): any {
    yield takeEvery(DAILYREPORT_LIST, DailyreportList);
}
export function* watchDailytargetList(): any {
    yield takeEvery(DAILYTARGET_LIST, TargetreportList);
}
export function* watchTargetreportDetailsList(): any {
    yield takeEvery(DAILYTARGET_DETAILS, TargetreportDetailsList);
}

export function* watchTargetreportweeklyDetailsList(): any {
    yield takeEvery(WEEKLYTARGET_DETAILS, TargetreportweeklyDetailsList);
}

export function* watchDailyticketList(): any {
    yield takeEvery(DAILYTICKET_LIST, TicketreportList);
}
export function* watchTicketreportDetailsList(): any {
    yield takeEvery(DAILYTICKET_DETAILS, TicketreportDetailsList);
}

export function* watchTicketreportweeklyDetailsList(): any {
    yield takeEvery(WEEKLYTICKET_DETAILS, TicketreportweeklyDetailsList);
}
function* authSaga(): any {
    yield all([
        fork(watchDailyreportList),
        fork(watchDailytargetList),
        fork(watchTargetreportDetailsList),
        fork(watchTargetreportweeklyDetailsList),
        fork(watchDailyticketList),
        fork(watchTicketreportDetailsList),
        fork(watchTicketreportweeklyDetailsList)
    ]);
}

export default authSaga;
