// @flow
import {
    WORK_DATA_LIST,
    WORK_DATA_LIST_SUCCESS,
    WORK_DATA_LIST_FAILED,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type viewReportListAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const viewEmployeeReportList = (state: State = INIT_STATE, action: viewReportListAction) => {
   
    switch (action.type) {

case WORK_DATA_LIST:
    return { ...state, listloading: true };
    case WORK_DATA_LIST_SUCCESS:
        return { ...state, viewReportEmployeeList: action.payload, listloading: false, error: null };
    case WORK_DATA_LIST_FAILED:
        return { ...state, error: action.payload, listloading: false };

   

    
        default:
            return { ...state };
    }
};

export default viewEmployeeReportList;
