// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { REPORTMONTH_TEAM_LIST ,REPORT_TEAM_LIST,REPORTTEAMMONTH_LIST,TEAM_MEMBERS_REPORT_LIST,TARGETTEAM_LIST,
    DAILYTARGETTEAM_LIST,
    MONTHLYTARGETTEAM_LIST,    LOGOUTLEAD_LIST,LOGOUTLEAD_POST

} from './constants';

import {
    getTeamReportMonthListSuccess,
    getTeamReportMonthListFailed,
    getTeamReportMembersListSuccess,
    getTeamReportListSuccess,
    getTeamReportListFailed,
    getTeamReportMembersListFailed,
    getTargetTeamLeadListSuccess,
    getTargetTeamLeadListFailed,
    getDailyTargetTeamLeadListSuccess,
    getDailyTargetTeamLeadListFailed,
    getMonthlyTargetTeamLeadListSuccess,
    getMonthlyTargetTeamLeadListFailed,
    getLogoutLeadListSuccess,
    getLogoutLeadListFailed,
    getLogoutLeadPostSuccess,
    getLogoutLeadPostFailed
} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const dailyreportAddedSucsess = () => toast.success('Dailyreport Added Successfully', { transition: Zoom });
const dailyreportDeletedSuccess = () => toast.success('Dailyreport Deleted Successfully', { transition: Zoom });
const dailyreportUpdated = () => toast.info('Dailyreport Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */

//Get Team Members List
function* TeamMembersList({payload:data}) {
    
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },

        method: 'POST',
        url: endpoints.teamlist,
        data: {

            "team_id":data.team_id,
          
            "user_id":data.user_id,
         }
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getTeamReportMembersListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTeamReportMembersListFailed(message));
    }
}

function* TeamReportMonthList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
        url: endpoints.teamreportmonthlist,
        // url: endpoints.dailyreportList,
         data: {

            "user_id":data.user_id,
            "start_date":data.date_daily,
            "end_date":data.enddate

         }
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getTeamReportMonthListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTeamReportMonthListFailed(message));
    }
}
function* TeamReportList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.teamreportlist,
        data: {

            "id":data.param_id,
          

         }
    };
    try {
        const response = yield call(ApiCall, options);

        yield put(getTeamReportListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTeamReportListFailed(message));
    }
}
//target

function* TargetTeamLeadList({payload:data}) {
    console.log(data);

    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.targetTeamLeadList,
        data: data
    };
    try {
        const response = yield call(ApiCall, options);
console.log(response);
        yield put(getTargetTeamLeadListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getTargetTeamLeadListFailed(message));
    }
}
//target daily details


function* TargetTeamLeadDailyDetails({payload:data}) {

    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.targetTeamLeadDaily,
        data: data
    };
    try {
        const response = yield call(ApiCall, options);
        yield put(getDailyTargetTeamLeadListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyTargetTeamLeadListFailed(message));
    }
}

function* TargetTeamLeadMonthlyDetails({payload:data}) {

    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.targetTeamLeadMonthly,
        data: data
    };
    try {
        const response = yield call(ApiCall, options);
        yield put(getMonthlyTargetTeamLeadListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getMonthlyTargetTeamLeadListFailed(message));
    }
}
function* getLogoutLeadListDetails({payload:data}) {

    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.logoutTeamLeadList,
        data: data
    };
    try {
        const response = yield call(ApiCall, options);
        yield put(getLogoutLeadListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getLogoutLeadListFailed(message));
    }
}

///

function* getLogoutLeadPostDetails({payload:data}) {

    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
       // url: endpoints.dailyreportList+`/${data.user_id}/${data.date_daily}`,
        url: endpoints.logoutTeamLeadPost,
        data: data
    };
    try {
        const response = yield call(ApiCall, options);
        yield put(getLogoutLeadPostSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getLogoutLeadPostFailed(message));
    }
}
export function* watchTargetTeamLeadList(): any {
    yield takeEvery(TARGETTEAM_LIST, TargetTeamLeadList);
}
export function* watchLogoutLeadPostDetails(): any {
    yield takeEvery(LOGOUTLEAD_POST, getLogoutLeadPostDetails);
}


export function* watchLogoutLeadListDetails(): any {
    yield takeEvery(LOGOUTLEAD_LIST, getLogoutLeadListDetails);
}

export function* watchTargetTeamLeadMonthlyDetails(): any {
    yield takeEvery(MONTHLYTARGETTEAM_LIST, TargetTeamLeadMonthlyDetails);
}

export function* watchTargetTeamLeadDailyDetails(): any {
    yield takeEvery(DAILYTARGETTEAM_LIST, TargetTeamLeadDailyDetails);
}

export function* watchTeamReportMonthList(): any {
    yield takeEvery(REPORTTEAMMONTH_LIST, TeamReportMonthList);
}

export function* watchTeamReportList(): any {
    yield takeEvery(REPORT_TEAM_LIST, TeamReportList);
}

export function* watchTeamList(): any {
    yield takeEvery(TEAM_MEMBERS_REPORT_LIST, TeamMembersList);
}

function* authSaga(): any {
    yield all([
        fork(watchTeamReportMonthList),
        fork(watchTeamReportList),
        fork(watchTeamList),
        fork(watchTargetTeamLeadList),
        fork(watchTargetTeamLeadDailyDetails),
        fork(watchTargetTeamLeadMonthlyDetails),
        fork(watchLogoutLeadListDetails),
        fork(watchLogoutLeadPostDetails)


    ]);
}

export default authSaga;
