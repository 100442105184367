/* REPORTMONTH */
export const REPORTTEAMMONTH_LIST = 'REPORTTEAMMONTH_LIST';
export const REPORTTEAMMONTH_LIST_SUCCESS = 'REPORTTEAMMONTH_LIST_SUCCESS';
export const REPORTTEAMMONTH_LIST_FAILED = 'REPORTTEAMMONTH_LIST_FAILED';




/* REPORT */
export const REPORT_TEAM_LIST = 'REPORT_TEAM_LIST';
export const REPORT_TEAM_LIST_SUCCESS = 'REPORT_TEAM_LIST_SUCCESS';
export const REPORT_TEAM_LIST_FAILED = 'REPORT_TEAM_LIST_FAILED';

// team members
export const TEAM_MEMBERS_REPORT_LIST = 'TEAM_MEMBERS_REPORT_LIST';
export const TEAM_MEMBERS_REPORT_LIST_SUCCESS = 'TEAM_MEMBERS_REPORT_LIST_SUCCESS';
export const TEAM_MEMBERS_REPORT_LIST_FAILED = 'TEAM_MEMBERS_REPORT_LIST_FAILED';



//target list

export const TARGETTEAM_LIST  ='TARGETTEAM_LIST';
export const  TARGETTEAM_LIST_SUCCESS ='TARGETTEAM_LIST_SUCCESS';
export const  TARGETTEAM_LIST_FAILED  ='TARGETTEAM_LIST_FAILED';


export const   DAILYTARGETTEAM_LIST='DAILYTARGETTEAM_LIST';
export const DAILYTARGETTEAM_LIST_SUCCESS='DAILYTARGETTEAM_LIST_SUCCESS';
export const DAILYTARGETTEAM_LIST_FAILED='DAILYTARGETTEAM_LIST_FAILED';

export const MONTHLYTARGETTEAM_LIST='MONTHLYTARGETTEAM_LIST';
export const MONTHLYTARGETTEAM_LIST_SUCCESS='MONTHLYTARGETTEAM_LIST_SUCCESS';
export const MONTHLYTARGETTEAM_LIST_FAILED='MONTHLYTARGETTEAM_LIST_FAILED';


export const LOGOUTLEAD_LIST='LOGOUTLEAD_LIST';
export const LOGOUTLEAD_LIST_SUCCESS='LOGOUTLEAD_LIST_SUCCESS';
export const LOGOUTLEAD_LIST_FAILED='LOGOUTLEAD_LIST_FAILED';



export const LOGOUTLEAD_POST='LOGOUTLEAD_POST';
export const LOGOUTLEAD_POST_FAILED='LOGOUTLEAD_POST_FAILED';
export const LOGOUTLEAD_POST_SUCCESS='LOGOUTLEAD_POST_SUCCESS';