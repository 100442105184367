/* DAILYREPORT */
export const DAILYREPORT_LIST = 'DAILYREPORT_LIST';
export const DAILYREPORT_LIST_SUCCESS = 'DAILYREPORT_LIST_SUCCESS';
export const DAILYREPORT_LIST_FAILED = 'DAILYREPORT_LIST_FAILED';

export const DAILYTARGET_LIST='DAILYTARGET_LIST';
export const DAILYTARGET_LIST_SUCCESS='DAILYTARGET_LIST_SUCCESS';
export const DAILYTARGET_LIST_FAILED='DAILYTARGET_LIST_FAILED';


export const DAILYTARGET_DETAILS='DAILYTARGET_DETAILS';
export const DAILYTARGET_DETAILS_SUCCESS='DAILYTARGET_DETAILS_SUCCESS';
export const DAILYTARGET_DETAILS_FAILED='DAILYTARGET_DETAILS_FAILED';



export const WEEKLYTARGET_DETAILS='WEEKLYTARGET_DETAILS';
export const WEEKLYTARGET_DETAILS_SUCCESS='WEEKLYTARGET_DETAILS_SUCCESS';
export const WEEKLYTARGET_DETAILS_FAILED='WEEKLYTARGET_DETAILS_FAILED';



export const DAILYTICKET_LIST='DAILYTICKET_LIST';
export const DAILYTICKET_LIST_SUCCESS='DAILYTICKET_LIST_SUCCESS';
export const DAILYTICKET_LIST_FAILED='DAILYTICKET_LIST_FAILED';
export const DAILYTICKET_DETAILS='DAILYTICKET_DETAILS';
export const DAILYTICKET_DETAILS_SUCCESS='DAILYTICKET_DETAILS_SUCCESS';
export const DAILYTICKET_DETAILS_FAILED='DAILYTICKET_DETAILS_FAILED';
export const WEEKLYTICKET_DETAILS='WEEKLYTICKET_DETAILS';
export const WEEKLYTICKET_DETAILS_SUCCESS='WEEKLYTICKET_DETAILS_SUCCESS';
export const WEEKLYTICKET_DETAILS_FAILED='WEEKLYTICKET_DETAILS_FAILED';

