// @flow
import {
    REPORTTEAMMONTH_LIST,
    REPORTTEAMMONTH_LIST_SUCCESS,
    REPORTTEAMMONTH_LIST_FAILED,
    REPORT_TEAM_LIST,
    REPORT_TEAM_LIST_SUCCESS,
    REPORT_TEAM_LIST_FAILED,
    TEAM_MEMBERS_REPORT_LIST,
    TEAM_MEMBERS_REPORT_LIST_SUCCESS,
    TEAM_MEMBERS_REPORT_LIST_FAILED,
    TARGETTEAM_LIST,
    TARGETTEAM_LIST_SUCCESS,
    TARGETTEAM_LIST_FAILED,
    DAILYTARGETTEAM_LIST,
    DAILYTARGETTEAM_LIST_SUCCESS,
    DAILYTARGETTEAM_LIST_FAILED,
    MONTHLYTARGETTEAM_LIST_SUCCESS,
    MONTHLYTARGETTEAM_LIST_FAILED,
    MONTHLYTARGETTEAM_LIST,
    LOGOUTLEAD_LIST,
    LOGOUTLEAD_LIST_SUCCESS,
    LOGOUTLEAD_LIST_FAILED,
    LOGOUTLEAD_POST,
    LOGOUTLEAD_POST_FAILED,
    LOGOUTLEAD_POST_SUCCESS

} from './constants';

type TeamReportAction = { type: string, payload: {} | string };

export const getTeamReportMembersList = (id): TeamReportAction => ({
    type: TEAM_MEMBERS_REPORT_LIST,
    payload: id,
  });
  
  export const getTeamReportMembersListSuccess = (team: string): TeamReportAction => ({
    type: TEAM_MEMBERS_REPORT_LIST_SUCCESS,
    payload: team,
  });
  
  export const getTeamReportMembersListFailed = (error: string): TeamReportAction => ({
    type: TEAM_MEMBERS_REPORT_LIST_FAILED,
    payload: error,
  });
  

export const getTeamReportMonthList = (data:{}): TeamReportAction => ({
    type: REPORTTEAMMONTH_LIST,
    payload: data,
});

export const getTeamReportMonthListSuccess = (reportmonth: string): TeamReportAction => ({
    type:REPORTTEAMMONTH_LIST_SUCCESS,
    payload: reportmonth,
});

export const getTeamReportMonthListFailed = (error: string): TeamReportAction => ({
    type: REPORTTEAMMONTH_LIST_FAILED,
    payload: error,
});

export const getTeamReportList = (data:{}): TeamReportAction => ({
    type:REPORT_TEAM_LIST,
    payload: data,
});

export const getTeamReportListSuccess = (reportmonth: string): TeamReportAction => ({
    type:REPORT_TEAM_LIST_SUCCESS,
    payload: reportmonth,
});

export const getTeamReportListFailed = (error: string): TeamReportAction => ({
    type: REPORT_TEAM_LIST_FAILED,
    payload: error,
});


//target




export const getTargetTeamLeadList = (data:{}): TeamReportAction => ({
    type: TARGETTEAM_LIST,
    payload: data,
});

export const getTargetTeamLeadListSuccess = (targetTeam: string): TeamReportAction => ({
    type:TARGETTEAM_LIST_SUCCESS,
    payload: targetTeam,
});

export const getTargetTeamLeadListFailed = (error: string): TeamReportAction => ({
    type: TARGETTEAM_LIST_FAILED,
    payload: error,
});
//details
export const getDailyTargetTeamLeadList = (data:{}): TeamReportAction => ({
    type: DAILYTARGETTEAM_LIST,
    payload: data,
});

export const getDailyTargetTeamLeadListSuccess = (targetDailyTeam: string): TeamReportAction => ({
    type:DAILYTARGETTEAM_LIST_SUCCESS,
    payload: targetDailyTeam,
});

export const getDailyTargetTeamLeadListFailed = (error: string): TeamReportAction => ({
    type:  DAILYTARGETTEAM_LIST_FAILED,
    payload: error,
});

export const getMonthlyTargetTeamLeadList = (data:{}): TeamReportAction => ({
    type:MONTHLYTARGETTEAM_LIST,
    payload: data,
});

export const getMonthlyTargetTeamLeadListSuccess = (targetMonthlyTeam: string): TeamReportAction => ({
    type: MONTHLYTARGETTEAM_LIST_SUCCESS,
    payload: targetMonthlyTeam,
});

export const getMonthlyTargetTeamLeadListFailed = (error: string): TeamReportAction => ({
    type:  MONTHLYTARGETTEAM_LIST_FAILED,
    payload: error,
});


//logout
export const getLogoutLeadList = (data:{}): TeamReportAction => ({
    type:LOGOUTLEAD_LIST,
    payload: data,
});

export const getLogoutLeadListSuccess = (leadLogoutList: string): TeamReportAction => ({
    type: LOGOUTLEAD_LIST_SUCCESS,
    payload: leadLogoutList,
});

export const getLogoutLeadListFailed = (error: string): TeamReportAction => ({
    type:  LOGOUTLEAD_LIST_FAILED,
    payload: error,
});

//logout post
export const getLogoutLeadPost = (data:{}): TeamReportAction => ({
    type:LOGOUTLEAD_POST,
    payload: data,
});

export const getLogoutLeadPostSuccess = (leadLogoutPost: string): TeamReportAction => ({
    type: LOGOUTLEAD_POST_SUCCESS,
    payload: leadLogoutPost,
});

export const getLogoutLeadPostFailed = (error: string): TeamReportAction => ({
    type:  LOGOUTLEAD_POST_FAILED,
    payload: error,
});