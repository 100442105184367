// @flow
import {
    REPORTTEAMMONTH_LIST,
    REPORTTEAMMONTH_LIST_SUCCESS,
    REPORTTEAMMONTH_LIST_FAILED,
    REPORT_TEAM_LIST,
    REPORT_TEAM_LIST_SUCCESS,
    REPORT_TEAM_LIST_FAILED,
    TEAM_MEMBERS_REPORT_LIST,
    TEAM_MEMBERS_REPORT_LIST_SUCCESS,
    TEAM_MEMBERS_REPORT_LIST_FAILED,
    TARGETTEAM_LIST,
    TARGETTEAM_LIST_SUCCESS,
    TARGETTEAM_LIST_FAILED,    DAILYTARGETTEAM_LIST,
    DAILYTARGETTEAM_LIST_SUCCESS,
    DAILYTARGETTEAM_LIST_FAILED,
    MONTHLYTARGETTEAM_LIST_SUCCESS,
    MONTHLYTARGETTEAM_LIST_FAILED,
    MONTHLYTARGETTEAM_LIST,
    LOGOUTLEAD_LIST,
    LOGOUTLEAD_LIST_SUCCESS,
    LOGOUTLEAD_LIST_FAILED,
    LOGOUTLEAD_POST,
    LOGOUTLEAD_POST_FAILED,
    LOGOUTLEAD_POST_SUCCESS
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type TeamReportAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const TeamReportMonthly = (state: State = INIT_STATE, action: TeamReportAction) => {
   
    switch (action.type) {
        case TEAM_MEMBERS_REPORT_LIST:
            return { ...state, listloading: true };
          case TEAM_MEMBERS_REPORT_LIST_SUCCESS:
            return {
              ...state,
              teamlister: action.payload,
              listloading: false,
              error: null,
            };
          case TEAM_MEMBERS_REPORT_LIST_FAILED:
            return { ...state, error: action.payload, listloading: false };
        case REPORTTEAMMONTH_LIST:
            return { ...state, listloading: true };
        case REPORTTEAMMONTH_LIST_SUCCESS:
            return { ...state, teamreportmonth: action.payload, listloading: false, error: null };
        case REPORTTEAMMONTH_LIST_FAILED:
            return { ...state, error: action.payload, listloading: false };
      
            case REPORT_TEAM_LIST:
            return { ...state, listloading: true };
            case REPORT_TEAM_LIST_SUCCESS:
                return { ...state, reportdata: action.payload, listloading: false, error: null };
            case REPORT_TEAM_LIST_FAILED:
                return { ...state, error: action.payload, listloading: false };
//target


case TARGETTEAM_LIST:
    return { ...state, listloading: true };
    case TARGETTEAM_LIST_SUCCESS:
        return { ...state, targetTeam: action.payload, listloading: false, error: null };
    case TARGETTEAM_LIST_FAILED:
        return { ...state, error: action.payload, listloading: false };
//details
case DAILYTARGETTEAM_LIST:
    return { ...state, listloading: true };
    case DAILYTARGETTEAM_LIST_SUCCESS:
        return { ...state, targetDailyTeam: action.payload, listloading: false, error: null };
    case MONTHLYTARGETTEAM_LIST_FAILED:
        return { ...state, error: action.payload, listloading: false };

        case MONTHLYTARGETTEAM_LIST:
    return { ...state, listloading: true };
    case MONTHLYTARGETTEAM_LIST_SUCCESS:
        return { ...state, targetMonthlyTeam: action.payload, listloading: false, error: null };
    case MONTHLYTARGETTEAM_LIST_FAILED:
        return { ...state, error: action.payload, listloading: false };



case LOGOUTLEAD_LIST:
    return { ...state, listloading: true };
    case LOGOUTLEAD_LIST_SUCCESS:
        return { ...state, leadLogoutList: action.payload, listloading: false, error: null };
    case LOGOUTLEAD_LIST_FAILED:
        return { ...state, error: action.payload, listloading: false };

        case LOGOUTLEAD_POST:
            return { ...state, listloading: true };
            case LOGOUTLEAD_POST_SUCCESS:
                return { ...state, leadLogoutPost: action.payload, listloading: false, error: null };
            case LOGOUTLEAD_POST_FAILED:
                return { ...state, error: action.payload, listloading: false };

    
        default:
            return { ...state };
    }
};

export default TeamReportMonthly;
