// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import {USERHASH_LIST,USERHASH_SHOW,
    USERHASH_SHOW_SUCCESS,
    USERHASH_SHOW_FAILED,TOPICHASH_SHOW,DEPTEAM_LIST,
    USERHASH_ADD_SUCCESS,
    USERHASH_ADD, 
    USERHASH_ADD_FAILED,
    USERTOPICHASH_UPDATE,
    USERTOPICHASH_UPDATE_SUCCESS,
    USERTOPICHASH_UPDATE_FAILED,USERSUBHASH_ADD,USERSUBTOPICHASH_UPDATE

    } from './constants';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    getUserhashListSuccess,
    getUserhashListFailed,
    getUserhashShowList,
    getUserhashShowListSuccess,
    getUserhashShowListFailed,
    getUserTopichashShowListSuccess,
    getUserTopichashShowListFailed,
    getUserDepTeamListSuccess,
    getUserDepTeamListFailed,getUserTopichashUpdate,getUserTopichashUpdateSuccess,getUserTopichashUpdateFailed,
    getUserhashTopicAdd,getUserhashTopicAddSuccess,getUserhashTopicAddFailed,getUserhashSubTopicAddSuccess,
    getUserhashSubTopicAddFailed,getUserSubTopichashUpdateSuccess,getUserSubTopichashUpdateFailed

} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const UserhashAddedSucsess = () => toast.success('HashBook Added Successfully', { transition: Zoom });
const UserhashDeletedSuccess = () => toast.success('HashBook Deleted Successfully', { transition: Zoom });
const UserhashUpdated = () => toast.info('HashBook Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
const TopichashAddedSucsess = () => toast.success('Topic Added Successfully', { transition: Zoom });
const TopichashDeletedSuccess = () => toast.success('Topic Deleted Successfully', { transition: Zoom });
const TopichashUpdated = () => toast.info('Topic Updated Successfully', { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* getUserhashList({payload:data}) {
    const user = getLoggedInUser();
  
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.hashbookuserList,
        data: {

            "id":data.param_id,
          

         },
    };
    
   
    const response = yield call(ApiCall, options);

    try {
        const response = yield call(ApiCall, options);

        yield put(getUserhashListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserhashListFailed(message));
    }
}


function* UserhashShowList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.UserhashShow,
        data: {

            "id":data.param_id,
          

         }
    };
    try {
        const response = yield call(ApiCall, options);

        yield put(getUserhashShowListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserhashShowListFailed(message));
    }
}
function* getUserTopichashList() {
    const user = getLoggedInUser();
  
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.TopichashShow,
        // data: sendData
    };
    
   

    try {
        const response = yield call(ApiCall, options);

        yield put(getUserTopichashShowListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserTopichashShowListFailed(message));
    }
}



function* UserDepTeamList({payload:data}) {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.UserHashDepTeam,
      
    };
    try {
        const response = yield call(ApiCall, options);

        yield put(getUserDepTeamListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserDepTeamListFailed(message));
    }
}

function* UserhashAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.UserHashAddTopic,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        TopichashAddedSucsess();
     //   console.log(response.data);
        yield put(getUserhashTopicAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserhashTopicAddFailed(message));
    }
}
function* UserTopichashUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.UserhashUpdate + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
    TopichashUpdated();
        yield put(getUserTopichashUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserTopichashUpdateFailed(message));
    }
}
///////////

function* UserSubhashAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.UserHashAddsubTopic,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        UserhashAddedSucsess();
     //   console.log(response.data);
        yield put(getUserhashSubTopicAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserhashSubTopicAddFailed(message));
    }
}
function* UserSubTopichashUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.UsersubhashUpdate ,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        UserhashUpdated();
        yield put(getUserSubTopichashUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserSubTopichashUpdateFailed(message));
    }
}

export function* watchgetUserhashList(): any {
    yield takeEvery( USERHASH_LIST, getUserhashList);
}

export function* watchUserhashShowList(): any {
    yield takeEvery( USERHASH_SHOW, UserhashShowList);
}
export function* watchgetUserTopichashList(): any {
    yield takeEvery( TOPICHASH_SHOW, getUserTopichashList);
}
export function* watchuserDepTeamList(): any {
    yield takeEvery( DEPTEAM_LIST, UserDepTeamList);
}
export function* watchuserHashAdd(): any {
    yield takeEvery( USERHASH_ADD, UserhashAdd);
}

export function* watchuserTopichashUpdate(): any {
    yield takeEvery( USERTOPICHASH_UPDATE, UserTopichashUpdate);
}
export function* watchuserSubhashAdd(): any {
    yield takeEvery( USERSUBHASH_ADD, UserSubhashAdd);
}
export function* watchuserSubTopichashUpdate(): any {
    yield takeEvery( USERSUBTOPICHASH_UPDATE, UserSubTopichashUpdate);
}
function* authSaga(): any {
    yield all([fork(watchgetUserhashList), fork(watchUserhashShowList),fork(watchgetUserTopichashList),
        fork(watchuserDepTeamList),fork(watchuserHashAdd),fork(watchuserTopichashUpdate),fork(watchuserSubhashAdd),
        fork(watchuserSubTopichashUpdate)
    ]);
}

export default authSaga;
